import './styles.css';
import { AWS } from 'aws-sdk';
import { Amplify } from 'aws-amplify';
import { Sha256 } from '@aws-crypto/sha256-js';
import amplifyconfig from './amplifyconfiguration.json';
import { CognitoIdentityClient, GetIdCommand, GetCredentialsForIdentityCommand } from "@aws-sdk/client-cognito-identity";
import { Credentials } from "@aws-sdk/client-sts";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { 
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
    RespondToAuthChallengeCommand
} from "@aws-sdk/client-cognito-identity-provider";
import {
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check';

console.log('app.js is loading -->');

function generateRandomString() {
    const randomValues = new Uint8Array(32);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(b => ('0' + b.toString(16)).slice(-2)).join('');
}

async function generateCodeChallenge(codeVerifier) {
    try {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashBase64 = btoa(String.fromCharCode.apply(null, hashArray))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
        return hashBase64;
    } catch (error) {
        console.error('Error generating code challenge:', error);
        throw error;
    }
}

// Immediate route protection
(async function protectRoute() {
    // Skip protection if we have a code (we're in auth callback)
    if (window.location.search.includes('code=')) {
        return;
    }

    const idToken = localStorage.getItem('idToken');
    const expiration = localStorage.getItem('tokenExpiration');
    const currentTime = Math.floor(Date.now() / 1000);
    
    // If no token or expired, redirect to auth
    if (!idToken || !expiration || currentTime >= parseInt(expiration)) {
        console.log('No valid token found, redirecting to auth...');
        const codeVerifier = generateRandomString();
        const codeChallenge = await generateCodeChallenge(codeVerifier);
        localStorage.setItem('codeVerifier', codeVerifier);
        window.location.href = `https://auth.augnos.ch/login?response_type=code&client_id=1ct0q58hul3gkslc4pkj60gatk&redirect_uri=https://portal.augnos.ch/index.html&code_challenge_method=S256&code_challenge=${codeChallenge}`;
        return;
    }
})();

Amplify.configure(amplifyconfig);

// Initialize Cognito Identity Provider client
const cognitoClient = new CognitoIdentityProviderClient({
    region: amplifyconfig.aws_cognito_region
});

function decodeJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function updateConnectionStatus(connected) {
    const connectionLight = document.getElementById('connectionLight');
    const connectionText = document.getElementById('connectionText');
    connectionLight.className = connected ? 'connection-status connected' : 'connection-status disconnected';
    connectionText.textContent = connected ? 'Connected' : 'Not Connected';
}

function checkTokenStatus() {
  const idToken = localStorage.getItem('idToken');
  const expiration = localStorage.getItem('tokenExpiration');

  if (!idToken || !expiration) {
    console.log('Token or expiration not found in localStorage');
    return { exists: false, expired: true };
  }

  const currentTime = Math.floor(Date.now() / 1000);
  const expirationTime = parseInt(expiration);
  const timeUntilExpiration = expirationTime - currentTime;

  const minutes = Math.floor(timeUntilExpiration / 60);
  const seconds = timeUntilExpiration % 60;

  const isExpired = timeUntilExpiration < 300;

  console.log(`
Token Status:
---------------------------------
Current time:      ${new Date(currentTime * 1000).toLocaleString()}
Expiration time:   ${new Date(expirationTime * 1000).toLocaleString()}
Time until expiry: ${minutes} minutes and ${seconds} seconds
Status:            ${isExpired ? 'Considered expired' : 'Valid'}
---------------------------------`);

  return { exists: true, expired: isExpired };
}

async function refreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
      console.error('No refresh token available');
      return false;
  }

  try {
      const response = await fetch('https://auth.augnos.ch/oauth2/token', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: `grant_type=refresh_token&client_id=1ct0q58hul3gkslc4pkj60gatk&refresh_token=${refreshToken}`
      });

      if (!response.ok) {
          throw new Error(`Token refresh failed: ${response.status}`);
      }

      const tokens = await response.json();
      const idTokenPayload = decodeJwt(tokens.id_token);

      localStorage.setItem('idToken', tokens.id_token);
      localStorage.setItem('accessToken', tokens.access_token);
      localStorage.setItem('tokenExpiration', idTokenPayload.exp);

      console.log('Tokens refreshed successfully');
      return true;
  } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
  }
}

function handleSignOut() {
  console.log('Sign out button clicked');

  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('codeVerifier');
  localStorage.removeItem('tokenExpiration');

  const clientId = '1ct0q58hul3gkslc4pkj60gatk';
  const signOutRedirectUri = encodeURIComponent('https://augnos.ch');
  const logoutUrl = `https://auth.augnos.ch/logout?response_type=code&client_id=${clientId}&logout_uri=${signOutRedirectUri}`;
  
  console.log('Preparing to redirect to:', logoutUrl);
  window.location.href = logoutUrl;
}

document.addEventListener('DOMContentLoaded', async () => {
  console.log('DOM beginning to load!');
  setupUI();

  try {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    
    if (code) {
      console.log('Processing authentication callback...');
      await handleAuthentication();
      updateUsername();
    } else {
      console.log('No authentication code found in URL');
      updateUsername();
    }

    // After authentication, check microphone permission
    await checkMicrophonePermission();
  } catch (error) {
    console.error('Error during initialization:', error);
    updateConnectionStatus(false);
  }
});

function setupUI() {
  const signOutButton = document.getElementById('signOutButton');
  console.log('Attempting to attach event listener to sign-out button');
  if (signOutButton) {
    console.log('Sign-out button found, attaching event listener');
    signOutButton.addEventListener('click', (event) => {
      event.preventDefault();
      console.log('Sign-out button clicked, calling handleSignOut');
      handleSignOut();
    });
  } else {
    console.error('Sign out button not found in the DOM');
  }
}

async function exchangeCodeForTokens(code, codeVerifier) {
  try {
    const response = await fetch(`https://auth.augnos.ch/oauth2/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `grant_type=authorization_code&client_id=1ct0q58hul3gkslc4pkj60gatk&code_verifier=${codeVerifier}&redirect_uri=https://portal.augnos.ch/index.html&code=${code}`
    });

    if (!response.ok) throw new Error(`Token request failed: ${response.status}`);
    return response.json();
  } catch (error) {
    console.error('Token exchange failed:', error);
    throw error;
  }
}

async function retrieveAwsCredentials() {
  const idToken = localStorage.getItem('idToken');
  if (!idToken) throw new Error('ID Token not found in localStorage');

  const credentialsProvider = fromCognitoIdentityPool({
    clientConfig: { region: amplifyconfig.aws_cognito_region },
    identityPoolId: amplifyconfig.aws_cognito_identity_pool_id,
    logins: {
      [`cognito-idp.${amplifyconfig.aws_cognito_region}.amazonaws.com/${amplifyconfig.aws_user_pools_id}`]: idToken
    }
  });

  return credentialsProvider();
}

async function handleAuthentication() {
  try {
    if (!window.location.search.includes('code=')) {
      await initiateLoginProcess();
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const codeVerifier = localStorage.getItem('codeVerifier');
    
    console.log('Exchanging code for tokens...');
    const tokens = await exchangeCodeForTokens(code, codeVerifier);
    if (!tokens.id_token) throw new Error('ID Token not received from authentication server');

    console.log('Tokens received successfully');
    storeTokens(tokens);
    console.log('Tokens stored and username updated');

    // Clear the URL parameters
    window.history.replaceState({}, document.title, window.location.pathname);

    return true;
  } catch (error) {
    console.error('Authentication error:', error);
    handleAuthenticationError(error);
    return false;
  }
}

function storeTokens(tokens) {
  const idTokenPayload = decodeJwt(tokens.id_token);
  localStorage.setItem('idToken', tokens.id_token);
  localStorage.setItem('accessToken', tokens.access_token);
  localStorage.setItem('refreshToken', tokens.refresh_token);
  localStorage.setItem('tokenExpiration', idTokenPayload.exp);
  updateUsername();
}

function updateUsername() {
    const idToken = localStorage.getItem('idToken');
    if (idToken) {
        const decodedToken = decodeJwt(idToken);
        const username = decodedToken.name || decodedToken.email || decodedToken.sub;
        const usernameElement = document.getElementById('username');
        if (usernameElement) {
            usernameElement.textContent = username;
        }
    }
}

async function initiateLoginProcess() {
  const codeVerifier = generateRandomString();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  localStorage.setItem('codeVerifier', codeVerifier);
  window.location.href = `https://auth.augnos.ch/login?response_type=code&client_id=1ct0q58hul3gkslc4pkj60gatk&redirect_uri=https://portal.augnos.ch/index.html&code_challenge_method=S256&code_challenge=${codeChallenge}`;
}

function handleAuthenticationError(error) {
  debugger;
  console.error('Authentication error:', error.message);
  alert('There was a problem with authentication. Please try logging in again.');
  // Clear any stored authentication data
  localStorage.removeItem('idToken');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpiration');
  // Redirect to login page
  window.location.href = 'https://auth.augnos.ch/login?response_type=code&client_id=1ct0q58hul3gkslc4pkj60gatk&redirect_uri=https://portal.augnos.ch/index.html';
}

// Define checkMicrophonePermission function
async function checkMicrophonePermission() {
    try {
        console.log('Checking microphone permissions...');
        const permissionResult = await navigator.permissions.query({ name: 'microphone' });
        
        console.log('Permission state:', permissionResult.state);
        
        // Listen for permission changes
        permissionResult.addEventListener('change', () => {
            console.log('Permission state changed to:', permissionResult.state);
            document.getElementById('connectionText').textContent = 
                `Microphone permission: ${permissionResult.state}`;
        });

        return {
            success: permissionResult.state === 'granted',
            state: permissionResult.state
        };
    } catch (err) {
        console.error('Permission check error:', err);
        return { success: false, error: err };
    }
}

// Expose it globally
window.checkMicrophonePermission = checkMicrophonePermission;